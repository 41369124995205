@import './loader.scss';

@font-face {
  font-display: fallback;
  font-family: OatmealPro;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/OatmealPro2-Regular.woff2') format('woff2');
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #222;
}

body {

  margin: 0;
  font-family: 'OatmealPro';
}

@mixin thumb() {
  &::-webkit-slider-thumb {
    appearance: none;
    @content;
  }

  &::-moz-range-thumb {
    border: 0;
    @content;
  }

  &::-ms-thumb {
    @content;
  }
}

@mixin track($color: red, $radius: 100%) {
  &::-webkit-slider-runnable-track {
    @content;
    border-radius: $radius;
    background-color: $color;
  }

  &::-moz-range-track {
    @content;
    border-radius: $radius;
    background-color: $color;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-ms-track {
    border: 0;
    @content;
    border-radius: $radius;
    background-color: $color;
    color: transparent;
  }

  &::-ms-fill-lower,
  &::-ms-fill-upper {
    background-color: transparent;
  }

  &::-ms-tooltip {
    display: none;
  }
}

input[type="range"i] {
  width: 100%;
  appearance: none;
  background-color: transparent;

  // handle active states
  &:active {
    @include thumb() {
      transform: scale(1.1);
      cursor: -webkit-grabbing;
      cursor: -moz-grabbing;
    }
  }

  // accessibility
  &:focus {
    outline: none;
  }

  // thumb styles
  @include thumb() {
    display: block;
    width: 3rem;
    height: 3rem;
    background: #004263;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: -webkit-grab;
    cursor: -moz-grab;
  }

  // track styles
  @include track(transparent, 3em) {
    height: 3rem;
    padding: .25rem;
    box-sizing: content-box;
  }
}

.call-container {
  background-color: #222;
  display: none;

  &.show {
    display: block;
  }
}

.pids-wrapper {
  width: 100%;
  max-width: 300px;
  display: flex;
  margin: 15px auto;
}

.pid {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 3px;
  border-radius: 0;
}



.background {
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.4);
  position: absolute;
  text-align: center;
  z-index: 5;
  display: none;



  a {
    text-decoration: none;
    color: #3957bd;
    font-weight: 900;
  }

  &.show {
    display: block;
  }

  .wrapper-ios {
    position: absolute;
    color: #555;
    width: 80%;
    max-width: 500px;
    background-color: white;
    border-radius: 24px;
    margin: 30px auto 0 auto;
    padding: 16px;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);

    .loader-wrap{
      height: 1em;
      display: block;
      margin-top: 8em;
      margin-bottom: 8em;
      #loader{
        width: 60px;
        height: 60px;
      }
    }

  }
  .wrapper-android {
    position: absolute;
    color: #555;
    width: 80%;
    max-width: 500px;
    background-color: white;
    border-radius: 24px;
    margin: 30px auto 0 auto;
    padding: 16px;
    top: 45%; left: 50%;
    transform: translate(-50%,-50%);

    .cover {
      max-width: 60px;
      margin: auto;
    }

    .loader-wrap{
      height: 1em;
      display: block;
      margin-top: 8em;
      margin-bottom: 8em;
      #loader{
        width: 60px;
        height: 60px;
      }
    }
  }

  .input-form{
    text-align: left;
    .radio-input{
      display:flex;
      flex-direction: row;
      vertical-align: middle;
      height: 2.5em;
      width: 100%;
      border: 1px solid #dddddd;
      margin-top: 5px;
      input[type=radio]{

          height: 93%;
          width: 1.3em;
          margin-left: 1.5em;
      }

      input[checked=checked]{
       background-color: red;
        height: 93%;
        width: 1.3em;
        margin-left: 1.5em;

    }
      span{
          padding-top: 4%;
          padding-left: 4%;
      }
    }
    .field{
      display: flex;
      flex-direction: column;
      label{
        font-size: 1em;
        margin-bottom: 4px;
      }
      input{
        width: 99%;
        border-radius: 0px;
        border: 1px solid #dddddd;
        height: 3.5em;
        &.error{
          border: 1px solid #C64729;
        }
      }
      .error-message{
        display: none;
        flex-direction: row;
        color: #C64729;
        font-size: 0.8em;
        margin-top: 4px;
        span{
          padding-left: 4px;
        }
        &.show{
          display: flex;
        }
      }

    }
  }
  .btn{
   width: 100%;
   border-radius: 8px;
   border: none;
   padding-left: 24px;
   padding-right: 24px;
   padding-top: 12px;
   padding-bottom: 12px;
   word-wrap: break-word;


   //line-height: 24px;
   .icon-container{
    display: inline-block;
   }
   span{
      flex: 1;
      text-align: left;
      font-size: 1.2em;
      font-weight: 600;
   }
  &.start-via-voice{
    background-color: white;
    border: #000 groove 1px;
    color:black;
    }


  }
  .accept {
    background-color: #3957bd;
    color: white;
  }



  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    hr{
      flex:2;
      height: 1px;
      background: var(--colors-border-default, #D9DBD0);
      border-width: 0px;
    }
    span{
      flex:1;
    }
  }


  p {
    font-size:15px ;
    font-weight: 400;
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
  }


}

.error-warning {
  z-index: 400;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.4);
  position: absolute;
  text-align: center;
  z-index: 5;
  display: none;

  a {
    text-decoration: none;
    color: #EEBB00;
    font-weight: 900;
  }

  &.show {
    display: block;
  }

  .wrapper {
    color: white;
    width: 100%;
    box-shadow: 5px 5px 8px rgb(0 0 0 / 30%);
    padding: 8px;
    background: #b00020;
  }

  p {
    margin: 30px auto;
  }

  h1 {
    margin: 10px 0;
    padding: 20px;
  }

  .logo {
    position: relative;
    top: 0;
    width: 100px;

  }



  .error-accept {
    background-color: white;
    padding: 10px 15px;
    color: #555;
    font-size: 1.5em;
    display: block;
    max-width: 40px;
    margin: 0 auto;
  }
}

.access-counter {
  -webkit-box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.75);
  background-color: whitesmoke;
  padding: 0.5em;
  /* margin-top: 1em; */
  color: #555;


  &.hidden {
    display: none;
  }
}

.slider-loading {

  width: 10em;
  height: 10em;
  overflow: hidden;
  margin-left: auto;
  margin-top: 2em;
  margin-right: auto;
  border-radius: 5em;
  display: none;

  img {
    width: 100%;
    height: 100%
  }

  &.show {
    display: block;
  }
}

.cozy-title-strong {
  color: var(--colors-text-default, #000);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Title/Strong */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 22px */
  letter-spacing: -0.4px;
}

.cozy-body-regular {
  color: var(--colors-text-lighter, #6E6E64);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* /Body/Regular */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */
}

.feedback {
  z-index: 400;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.4);
  position: absolute;
  text-align: center;
  z-index: 5;
  display: none;

  .cover {
    max-width: 60px;
    margin: auto;
  }

  a {
    text-decoration: none;
    color: #EEBB00;
    font-weight: 900;
  }

  .granted {
    display: none;

    &.show {
      display: block;
    }
  }

  .denied {
    display: none;

    &.show {
      display: block;
    }
  }

  &.show {
    display: block;
  }

  .wrapper {
    color: #555;
    width: 80%;
    max-width: 500px;
    background-color: white;
    box-shadow: 5px 5px 8px rgba($color: #000000, $alpha: .3);
    margin: 30px auto 0 auto;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 24px;
  }

  p {
    margin: 1em auto;
  }

  h1 {
    margin: 10px 0;
    padding-top: 32px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .logo {
    position: relative;
    top: 0;
    width: 100px;
  }

  .feedback-accept {
    background-color: #FFCC00;
    padding: 10px 15px;
    color: #184E67;
    font-size: 1.5em;
    display: block;
    border-radius: 0em;
  }

  #keys-container {
    .key {

      width: 80%;
      max-width: 450px;
      position: relative;
      margin: 30px auto 0 auto;



      &.hidden {
        display: none;
      }

      .access-name {
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.2em;
        background-color: whitesmoke;
        margin: 0;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        text-align: center;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        color: #555;
        z-index: 0;
        position: relative;
      }

      .slider-container {
        padding: 0.5em;
        background-color: whitesmoke;
        border-radius: 3em;
        -webkit-box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.75);


      }

      .slider-background {
        width: 100%;
        height: 3.75em;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(246, 208, 0, 1) 100%);
        border-radius: 4em;
        position: relative;
        //background-image: url("image/arrow.png");

        .unlock {
          position: absolute;
          height: 2em;
          width: 2em;
          right: 3px;
          top: 3px;
          padding: 0.5em;
          border: solid rgba(#004b63, 0.5);
          border-width: 3px;
          border-radius: 100%;

          img {
            height: 100%;
            max-width: 100%;
          }
        }

        .arrow {
          position: absolute;
          height: 2em;
          width: 7em;
          left: 5em;
          padding-top: 0.8em;
        }
      }
    }
  }
}

#snapshot {
  display: none;
  // position: absolute;
  // visibility: hidden;
  // z-index: -999;
}


.row>* {
  padding-right: 0;
  padding-left: 0;
}



#call-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-container {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #eee;
  text-align: center;

}

.avatar-container h1 {
  margin-top: 20px;
  font-weight: 500;
}

.avatar {
  width: 100%;
}

.avatar-muted {
  display: none;
  width: 25px;
  padding: 0;
}

.avatar-muted.show {
  display: inline;
}

.main-logo-container {
  position: absolute;
  width: 100%;
  height: 3em;
  top: 0em;
  z-index: 1;
  background-color: #3957BD;

  .logo {
    margin: auto;
    position: relative;
    max-height: 3em;
    display: block;
    z-index: 2;
  }
}

#report-problem-btn {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  z-index: 1;
  border-radius: 100%;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  font-size: 1.3em;
  font-weight: bolder;
  right: 12px;
  bottom: 24px;
  text-align: center;
}

.face-frame {
  height: 130%;
  width: 100%;
  background-image: url(/image/face-frame.png);
  background-size: cover;
  background-position: center;
  background-position-x: 50%;
  z-index: 1;
  position: absolute;
  top: -7%;
  display: none;

  &.show {
    display: block;
  }

  p {
    color: #fff;
    font-size: 1.2em;
    text-align: center;
    margin-top: 7em;
    margin-left: 2em;
    margin-right: 2em;
  }

  .shutter {
    position: absolute;
    top: 11em;
    right: 1em;
    z-index: 2;
    width: 4em;
    height: 4em;
    display: none;

    &.show {
      display: block;
    }
  }
}

.door-man-video-container {
  background-color: #000;
  position: absolute;
  width: 40%;
  z-index: 2;
  right: 0;
  margin: 10px;
  display: none;
  max-width: 300px;
  padding: 7px;
  background: rgba(0, 0, 0, 0.5);
  color: white;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px 0 0 0;
    font-size: 1em;

    img {
      width: 20px;
      margin: 0 10px;
    }
  }


  video {
    width: 100%;
  }

  &.show {
    display: block;
  }

  #door-man-video {
    object-fit: cover;
    display: block;
  }
}

.call-video-container {
  display: none;

  &.show {
    display: block;
  }
}

.user-video-container {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;

  #user-video {
    background-color: #222;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.8s;
    z-index: -2;
  }

  #user-video.movedToTop {
    margin: 10px;
    width: 30%;
    height: 20%;
    object-fit: cover;
  }

  #atendente {
    position: absolute;
    width: 20%;
    height: 20%;
    object-fit: cover;
    z-index: 99999;
    background-color: transparent;
  }
}

.call-information {
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 0 5%;

  .call-in-progress {
    display: none;

    &.show {
      display: block;
    }
  }
}

.call-interations {
  padding-bottom: 22%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);

  &.hidden {
    display: none;
  }
}

.loader-wrap {
  display: none;

  &.show {
    display: block;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 2px 2px rgb(0 0 0 / 40%);
  z-index: 1;


  .volume {
    height: 100%;
    object-fit: cover;
  }

  .volume-container {
    background-color: rgba($color: #000000, $alpha: 0.4);
    border-radius: 1em;
    position: absolute;
    top: 6em;
    left: 5em;
    transform: translate(-50%, -50%);
    display: none;
    margin-left: 1em;

    .btn-type-info{
        background-color: transparent;
        border: none;
        color: white;
        font-size: 1.0em;
        margin: 0;
        padding: 1.1em;


    }

    .volume-bar-container {
      float: left;
      height: 100%;
    }

    .volume-label-container {
      padding: 10%;
      color: white;
      height: 100%;

      img {
        width: 50%;
        height: 50%;
      }
    }


  }

  .row {
    display: flex;
    margin-right: 0;
    margin-left: 0;
  }

  &::before {
    position: absolute;
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1.6));
    width: 100%;
    height: 400px;
    bottom: 0;
    content: '';
    left: 0;
    z-index: 1;
  }

  .questionContainer {
    width: 100%;
    height: fit-content;
    text-align: center;
    color: white;
    z-index: 2;

  }

  .call-button {
    color: white;
  }

  .user-info {
    flex: 3;

    #instructions {
      display: block;

      &.hidden {
        display: none;
      }
    }
  }

  .info-container {
    overflow: hidden;
    flex: 0;
    display: none;

    &.show {
      flex: 3;
      display: block;
    }
  }

  .form-container {
    position: fixed;
    width: 100%;
    top: 54%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;

    .close-container {
      text-align: end;
      top: 0;

      button {
        width: 5em;
        height: 2em;
        color: white;
        background-color: transparent;
        border: none;
        padding-right: 1em;
        padding-top: 1em;
        text-align: end;
      }
    }

    &.show {
      display: block;
    }

    &.hidden {
      display: none;
    }

    .question {
      text-align: left;
      width: 92%;
      height: 100%;
      padding-left: 1em;
      z-index: 2;

      &.hidden {
        display: none;
      }

      .next-container {
        width: 100%;
        text-align: right;
        height: 40px;
        margin-top: 1em;
        display: flex;
        justify-content: space-between;

        .next {
          background-color: #004b63;

          color: white;
          font-size: 1em;
          cursor: pointer;
          width: 6em;
          padding: 0px;
        }
      }

      .label-container {
        margin-bottom: 1em;

        label {
          font-size: 1.5em;
          width: 100%;
          color: white;

        }
      }

      .input-container {
        input {
          background-color: transparent;
          border-color: transparent;
          border-bottom: 3px solid white;
          color: white;
          width: 92%;
          height: 2em;
          font-size: 1.5em;

        }

        p {
          visibility: hidden;
          color: rgb(243, 136, 136);
          margin: 0 0 0 0;

          &.show {
            visibility: visible;
          }
        }


        .options-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          list-style-type: none;
          padding: 0;


          li {

            height: 50px;
            position: relative;
            flex: 1;
            text-align: center;
          }

          label,
          input {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 3px;
          }

          input[type="radio"] {
            opacity: 0.0;
            z-index: 100;

          }

          input[type="radio"]:checked+label {
            background: lightblue;
            color: black;
            text-shadow: 0px 0px rgba(0, 0, 0, 0);

          }

          label {
            padding: 5px;
            border: 1px solid #CCC;
            border-radius: 0em;
            cursor: pointer;
            z-index: 90;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
          }




          button {
            height: 2em;
            width: 40%;
            background-color: transparent;
            border-color: white;
            border-width: 1px;
            color: white;
            font-size: 1.5em;
            border-radius: 1.5em;
          }

        }
      }

    }
  }


  .menu {


    &.show {
      display: block;
    }

    &.hidden {
      display: none;
    }

    flex:2;

    .keyboard-hint {
      &.hidden {
        display: none;
      }
    }

    .keyboard {
      width: 50%;
      background-color: transparent;
      border: 2px white;

      &.hidden {
        display: none;
      }

      img {
        max-height: 2em;
      }
    }


  }

  .text-field {
    align-items: center;
    color: white;
    text-align: left;
    line-height: normal;
    margin-left: 0.2em;
    transition: all 500ms ease-in-out;
    display: none;

    &.show {
      display: block;
    }

    .label {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      margin: 10px 10px 10px 0;
      font-size: 12px;
    }

    .value {
      width: 100%;
      white-space: nowrap;
      font-size: 20px;
    }
  }
}

.not-allow-android-gif {
  height: 275px;
  top: 11em;
  right: 1em;
  z-index: 2;

  &.show {
    display: block;
  }
}

button:active {
  border-style: none !important;
  border-top-style: none !important;
  border-right-style: none !important;
  border-bottom-style: none !important;
  border-left-style: none !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

/*******************************************
*********** BEGINNING
*********** Intents properties
*******************************************/


.display-visitor {
  background-color: #1f6178;
  line-height: 20px;
  width: 500px;
  border-radius: 200px;
  text-align: center;
  margin-bottom: 20px;

  h2 {
    margin: 10px;
  }
}

.Phone {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-width: 0px;
  margin-bottom: 0.2em;
  width: 4em;
  height: 4em;
  border-radius: 100%;
  background-color: #20b953;
  transform: translate3d(0, 0, 0) scale(1);
  &.hidden{
    display: none;
  }
}

.Phone::before,
.Phone::after {
  position: absolute;
  content: '';
}



.Phone::after {
  top: 0.45em;
  left: 0.45em;
  width: 3em;
  height: 3em;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}

.is-animating {
  animation: phone-outer 3000ms infinite;

  &::before {
    animation: phone-inner 3000ms infinite;
  }

  &::after {
    animation: phone-icon 3000ms infinite;
  }
}

.end-call {
  display: block;
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  display: none;

  &.show {
    display: block;
    z-index: 3;
  }
}

.rotate {
  background-color: #f44336;
  animation: rotation 0.3s ease-out;
  transition: background-color 0.3s ease-out;
  transform: rotate(132deg);
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(132deg);
  }
}

.start-call {

  &.hidden {
    display: none;
  }
}

.call-feedback {

  display: none;

  &.show {
    display: block;
  }
}

.rotate-hangup {
  background-color: #00d94a;
  -webkit-animation: rotation-back 0.3s ease-out;
  -webkit-transition: background-color 0.3s ease-out;
  transform: rotate(228deg);
}

@-webkit-keyframes rotation-back {
  from {
    -webkit-transform: rotate(132deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(#00d94a, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
  }

  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(#00d94a, 0.1), 0em 0.05em 0.1em rgba(#000000, 0.5);
  }

  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(#00d94a, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(#00d94a, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }

  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }

  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}

@keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0);
  }

  2% {
    transform: translate3d(0.01em, 0, 0);
  }

  4% {
    transform: translate3d(-0.01em, 0, 0);
  }

  6% {
    transform: translate3d(0.01em, 0, 0);
  }

  8% {
    transform: translate3d(-0.01em, 0, 0);
  }

  10% {
    transform: translate3d(0.01em, 0, 0);
  }

  12% {
    transform: translate3d(-0.01em, 0, 0);
  }

  14% {
    transform: translate3d(0.01em, 0, 0);
  }

  16% {
    transform: translate3d(-0.01em, 0, 0);
  }

  18% {
    transform: translate3d(0.01em, 0, 0);
  }

  20% {
    transform: translate3d(-0.01em, 0, 0);
  }

  22% {
    transform: translate3d(0.01em, 0, 0);
  }

  24% {
    transform: translate3d(-0.01em, 0, 0);
  }

  26% {
    transform: translate3d(0.01em, 0, 0);
  }

  28% {
    transform: translate3d(-0.01em, 0, 0);
  }

  30% {
    transform: translate3d(0.01em, 0, 0);
  }

  32% {
    transform: translate3d(-0.01em, 0, 0);
  }

  34% {
    transform: translate3d(0.01em, 0, 0);
  }

  36% {
    transform: translate3d(-0.01em, 0, 0);
  }

  38% {
    transform: translate3d(0.01em, 0, 0);
  }

  40% {
    transform: translate3d(-0.01em, 0, 0);
  }

  42% {
    transform: translate3d(0.01em, 0, 0);
  }

  44% {
    transform: translate3d(-0.01em, 0, 0);
  }

  46% {
    transform: translate3d(0em, 0, 0);
  }
}

////////////////////////////////////////////////////////////////////////////////////

.bell-ringing {
  -webkit-animation: ring 8s 1s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 8s 1s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 8s 1s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}


[type='radio']{
  accent-color: #3957bd;
  cursor: pointer;
}


#main-form{
  .field{
    margin-top: 5px;
  }
  .terms{
    margin: 16px 0 16px 0;
  }
  .btn{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;

    span {
      flex: inherit;
      margin-left: 8px;
    }
  }
}
.radio-input-group{
  margin-top: 16px;
}
.radio-input{
  cursor: pointer;
}
